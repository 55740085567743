/* @import url('https://fonts.googleapis.com/css2?family=Inter:wght@100..900&family=Kalnia:wght@700&family=Lato:wght@400;700&display=swap'); */

@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: "Inter-Regular";
  src: local("Inter-regular"),
    url(./fonts/Inter/Inter-Regular.ttf) format("truetype");
  font-display: block;
}

@font-face {
  font-family: "Inter-Black";
  src: local("Inter-black"),
    url(./fonts/Inter/Inter-Black.ttf) format("truetype");
  font-display: block;
}

@layer utilities {
  .text-shadow {
    text-shadow: 5px 5px 3px rgba(0, 0, 0, 0.3);
  }
}

input:-webkit-autofill,
input:-webkit-autofill:focus {
  transition: background-color 600000s 0s, color 600000s 0s;
}
input[data-autocompleted] {
  background-color: transparent !important;
}

input:autofill {
  background: transparent !important;
  color: #37393d;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus {
  -webkit-text-fill-color: #94a3b8 !important;
}
input:-webkit-autofill:active {
  color: "#94a3b8" !important;
  -webkit-text-fill-color: #94a3b8 !important;
  -webkit-box-shadow: 0 0 0 30px #1f2937 inset !important;
  background-clip: content-box !important;
}

#root {
  height: 100vh;
}

input:-webkit-autofill {
  -webkit-text-fill-color: #94a3b8 !important;
}

body {
  overflow-x: hidden;
  margin: 0;
  height: 100%;
  overscroll-behavior-x: none;
  -ms-scroll-chaining: none;
  font-family: "Inter-Regular";
  background-color: #191c2a;
  background-attachment: fixed;
  background-size: cover;
}

.fontBlack {
  font-family: "Inter-Black";
}

.gradientBackground {
  overflow-x: hidden;
  margin: 0;
  height: 100%;
  overscroll-behavior-x: none;
  -ms-scroll-chaining: none;
  font-family: "Inter-Regular";

  background-color: #0d0d0d;
  background-image: radial-gradient(
      circle at 20% 30%,
      #87fcf70d 0%,
      transparent 25%
    ),
    radial-gradient(circle at 80% 40%, #87fcf70d 0%, transparent 25%),
    radial-gradient(circle at 50% 80%, #87fcf70d 0%, transparent 25%),
    url("/src/assets/svg/bg-gradient.svg");
  background-attachment: fixed;
  background-size: cover;
  background-repeat: no-repeat;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

.mobile-dnd-container {
  min-height: 50vh;
}

.content-container {
  min-height: calc(100vh - 244px);
}

.footer--pin {
  position: relative;
  left: 0;
  bottom: 0;
}

input::-ms-clear,
input::-ms-reveal {
  display: none;
}

.noselect {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently                                supported by Chrome, Edge, Opera and Firefox */
}

.no-overscroll-y {
  overscroll-behavior-y: none;
}

.scroll-bar > div:first-child {
  overflow-x: hidden !important;
}
.scroll-bar > div:first-child::-webkit-scrollbar {
  background: transparent;
}

.underline {
  text-decoration-skip-ink: none;
}

.thick-strike {
  text-decoration-thickness: 2px;
}

.singleline-ellipsis {
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
}

.doubleline-ellipsis {
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

@media (min-width: 1024px) {
  .lg\:h-14\.399 {
    height: 14.399rem;
  }
  .lg\:h-19\.5 {
    height: 19.5rem;
  }
}

@media (min-width: 1800px) {
  .xl\:h-21\.25 {
    height: 21.25rem;
  }
}

.safari-image {
  z-index: -1;
  object-fit: cover;
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
}

.select__input {
  color: transparent;
}

img {
  pointer-events: none;
}

.font-face-chedros {
  font-family: "CHEDROS-Regular";
}

.font-face-chedros-bold {
  font-family: "CHEDROS-Bold";
}

.background {
  background-image: url("/src/assets/svg/gradient-landing-page.svg");
  background-size: cover;
  background-attachment: fixed;
  overflow: hidden;
}

.background-IOS {
  background-color: #191c2a;
}
